import React, { Suspense, Fragment } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { routes } from './routes';
import { createBrowserHistory } from 'history';
import AuthContext from "./context/Auth";
import PageLoading from './component/PageLoading';
import AuthGuard from './component/AuthGuard';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import 'react-toastify/dist/ReactToastify.css';


const history = createBrowserHistory();

function App() {
  return (
    <div className="App wrapper">
      <AuthContext>
        <Router history={history}>
          <RenderRoutes data={routes} />
        </Router>
      </AuthContext>
    </div>
  );
}

export default App;


function RenderRoutes(props) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {props.data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                <Guard>
                  {route.routes
                    ? <RenderRoutes data={route.routes} />
                    : <Component {...props} />}
                </Guard>
              )}
            />
          )
        })}

      </Switch>
    </Suspense>
  )
}