import React from 'react';

export default function PageLoading() {
    return (
        <div className="d-flex justify-content-center align-items-center login-left page-loading">
            <div className="spinner-border" role="status" style={{ color: 'gray' }}>
                <span className="sr-only" style={{ color: "black" }}>Loading...</span>
            </div>
            <br></br>
            <h3>Loading...</h3>
        </div>
    )
}

export const Loading = () => {
    return (
        <div className="d-flex justify-content-center align-items-center account-status">
            <div className="spinner-border" role="status" style={{ color: '#00204A' }}></div>
        </div>
    )
}
