import React, { createContext, useState, useMemo, useEffect } from 'react';
import axios from 'axios';
import ApiConfig from '../Config/ApiConfig';
import Web3 from 'web3';

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  } else {
    localStorage.removeItem('accessToken');
  }
};

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(false);
  const [userAccount, setUserAccount] = useState('');
  const [userType, setUserType] = useState('USER');
  const [isLoading, setIsLoading] = useState(false);
  const [accountChange, setAccountChange] = useState('');
  const authConnectWalletHandler = async (walletAddress) => {
    setIsLoading(true);
    const web3 = (window.web3 = new Web3(window.ethereum));

    if (walletAddress) {
      axios
        .post(ApiConfig.addWalletAddress, {
          walletAddress: web3.utils.toChecksumAddress(walletAddress),
        })
        .then((res) => {
          console.log('RES', res);
          setIsLoading(false);
          if (res.data.responseCode === 200) {
            setSession(res.data.result.token);
            setIsLogin(true);
            setUserAccount(walletAddress);
            setUserType(res.data.result.userType);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log('ERR', err);
          authConnectWalletHandler(walletAddress);
        });
    }
  };

  useEffect(() => {
    if (accountChange) {
      authConnectWalletHandler(accountChange);
    }
  }, [accountChange]); // eslint-disable-line react-hooks/exhaustive-deps

  //ACCOUNT CHANGE
  useMemo(
    () =>
      window.ethereum &&
      window.ethereum.on('accountsChanged', async function (accounts) {
        if (accounts.length > 0) {
          //   window.location.reload();
          setAccountChange(accounts[0]);
        } else {
          setSession('');
          setIsLogin(false);
          setUserAccount('');
          //   alert('Please Connect to wallet');
        }
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  //NETWORK CHANGE
  useMemo(() => {
    if (window.ethereum) {
      window.ethereum.on('networkChanged', async function (networkId) {
        // await connectToWallet();
        window.location.reload();
        // setNetworkId(networkId);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let data = {
    userLoggedIn: isLogin,
    userAccount,
    userType,
    isLoading,
    connectWalletHandler: (walletAddress) =>
      authConnectWalletHandler(walletAddress),
    logoutWallet: () => {
      setSession('');
      setIsLogin(false);
      setUserAccount('');
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
