let DomainURL = `${process.env.REACT_APP_API_URL}/api/v1/`;
// 'http://182.72.203.245:1850/api/v1/';

let user = `${DomainURL}user`;
let admin = `${DomainURL}admin`;

const ApiConfig = {
  //USER
  addWalletAddress: `${user}/addWalletAddress`,
  transferNft: `${user}/transferNft`,
  transferErc20: `${user}/transferErc20`,
  transactionListParticularUser: `${user}/transactionListParticularUser`,
  viewNftList: `${user}/viewNftList`,
  viewErc: `${user}/viewErc`,
  transferBatch: `${user}/transferBatch`,
  addImage: `${user}/addImage`,
  nftDetails: `${user}/nftDetails`,

  //ADMIN
  createNft: `${admin}/createNft`,
  createErc20: `${admin}/createErc20`,
  nftDetailsAdmin: `${admin}/nftDetailsAdmin`,
};

export default ApiConfig;
