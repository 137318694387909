import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

export const routes = [

    {
        exact: true,
        path: '/genrate-wallet',
        // guard: true,
        component: lazy(() => import('./pages/GenrateWallet'))
    },
    {
        exact: true,
        path: '/dashboard',
        // guard: true,
        component: lazy(() => import('./pages/HomePage'))
    },
    // {
    //     exact: true,
    //     path: '/transfer-nft',
    //     // guard: true,
    //     component: lazy(() => import('./pages/TransferNFT'))
    // },
    // {
    //     exact: true,
    //     path: '/create-nft',
    //     // guard: true,
    //     component: lazy(() => import('./pages/CreateNFT'))
    // },
    {
        exact: true,
        path: '/',
        // guard: true,
        component: lazy(() => import('./pages/Login'))
    },

    {
        component: () => <Redirect to="/404" />
    }
]
